import React from "react";
import {
  Dialog,
  DialogContent,
  Button,
  IconButton,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { TextField } from "components/custom/FormElements";
import { Row, Col } from "reactstrap";
import { Form } from "react-final-form";
import IntlMessages from "utils/IntlMessages";
import { required } from "config/InputErrors";
import { Close } from "@material-ui/icons";
import async from "async";
import { connect } from "react-redux";
import UserService from "services/User";
import SettingsActions from "store/reducers/Settings";
import { useTheme } from "@material-ui/core/styles";

const apiResendCode = (data, cb) => {
  UserService.resendVerificationCode(data).then((response) =>
    cb(response.errors, response.data)
  );
};

const CodeToLoginModal = ({
  open,
  toggleLoading,
  toggleAlert,
  onClose,
  authId,
  onLoginWithCode,
}) => {
  const { palette } = useTheme();
  const validateCode = (dataForm) => {
    const data = { verificationCode: dataForm.code };
    onLoginWithCode(data);
  };

  const resendCode = () => {
    toggleLoading(true);
    async.waterfall([apiResendCode.bind(this, authId)], (error, results) => {
      if (error) {
        toggleLoading(false);
        return toggleAlert(error);
      }

      toggleLoading(false);
      return toggleAlert("emailWithVerificationCodeSended", "info");
    });
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle
        disableTypography
        style={{ paddingTop: "30px", paddingBottom: "5px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            color="textPrimary"
            style={{ fontWeight: "600" }}
          >
            <IntlMessages id="appModule.login" />
          </Typography>
          {onClose ? (
            <IconButton aria-label="close" onClick={onClose}>
              <Close style={{ color: palette.secondary.dark }} />
            </IconButton>
          ) : null}
        </div>
      </DialogTitle>
      <DialogContent style={{ marginBottom: "30px" }}>
        <Form
          onSubmit={validateCode}
          render={({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              id="codeToLoginForm"
              className="login-modal-form"
              noValidate
            >
              <Row>
                <Col>
                  <TextField
                    field="code"
                    labelGap="20px"
                    placeholder="placeHolderCodeField"
                    label="enterCodeForLogin"
                    validate={required}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{ textAlign: "left" }}>
                  <Button
                    variant="text"
                    className="jr-btn text-primary"
                    onClick={resendCode}
                  >
                    <IntlMessages id="resendCode" />
                  </Button>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <Button
                    variant="outlined"
                    className="jr-btn btn-register-code border-danger text-danger"
                    onClick={onClose}
                  >
                    <IntlMessages id="cancelBtn" />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className="jr-btn btn-register-code"
                    type="submit"
                  >
                    <IntlMessages id="validateCodeConfirmBtn" />
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};
const mapStateToProps = ({ user, settings }) => ({
  isLoading: settings.isLoading,
});

const mapDispatchToProps = {
  toggleLoading: SettingsActions.toggleLoading,
  toggleAlert: SettingsActions.toggleAlert,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CodeToLoginModal);
